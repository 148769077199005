input {
    background-color: #FFFFFF;
    border-radius: 5px;
    border: 1.5px solid #FF9100;
    margin: 5px;
    box-sizing: border-box;
    color: #6C6C6C;
    padding: 5px 5px 5px 20px;
    font-size: 16px;
    width: 343px;
}

input:active {
    border: 1.5px solid #D88219;
}

input:disabled {
    border: 1.5px solid rgba(255, 168, 0, 0.32);
}

@media (max-width: 480px) {
    input {
        width: 220px;
    }
}

@media (max-width: 845px) {
    input {
        width: 220px;
    }
}

@media (max-height: 391px) {
    input {
        width: 220px;
    }
}